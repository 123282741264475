export const menuConcat = (menuObj, menuList) => {
  const menuTree = menuObj.menuTree.map((o) => {
    if (o.childMenu && o.childMenu.length) {
      return {
        name: o.title,
        url: o.funUrl.replace('#', ''),
        permissionId: o.permissionId,
        children: o.childMenu.map((i) => {
          return {
            name: i.title,
            url: i.funUrl.replace('#', ''),
            permissionId: i.permissionId
          }
        })
      }
    } else {
      return {
        name: o.title,
        url: o.funUrl.replace('#', ''),
        permissionId: o.permissionId
      }
    }
  })
  // 第一级菜单合并
  for (const key in menuList) {
    const findObj = menuTree.find((i) => i.url === menuList[key].url)
    if (findObj) {
      const obj = JSON.parse(JSON.stringify(findObj))
      if (menuList[key].children && menuList[key].children.length) {
        const children = menuList[key].children
        obj.children = children
      }
      menuList[key] = {
        ...obj,
        ...menuList[key]
      }
    }
  }
  // 第二级菜单合并
  for (const item of menuList) {
    if (item.children && item.children.length) {
      for (const key in item.children) {
        for (const o of menuTree) {
          if (o.children && o.children.length) {
            for (const idx in o.children) {
              if (o.children[idx].url === item.children[key].url) {
                const obj = o.children[idx]
                if (obj) {
                  item.children[key] = obj
                }
                break
              }
            }
          }
        }
      }
    }
  }
  // 合并完成后, 给出没有合并的菜单的提示, 后面删除
  // 一级菜单和一级菜单合并, 二级菜单和二级菜单合并
  // for (const item of menuList) {
  // if (!item.permissionId) {
  //   item.name += '  *'
  // }
  // for (const idx in item.children) {
  //   if (!item.children[idx].permissionId) {
  //     item.children[idx].name += '*'
  //   }
  // }
  // }
  // 第一级过滤
  menuList = menuList.filter((item) => {
    if (item.permissionId) {
      return menuObj.ownedPermissionIds.includes(item.permissionId)
    } else {
      return true
    }
  })
  // 第二级过滤
  for (const item of menuList) {
    if (item.children && item.children.length) {
      item.children = item.children.filter((child) => {
        if (child.permissionId) {
          return menuObj.ownedPermissionIds.includes(child.permissionId)
        } else {
          return true
        }
      })
    }
  }
  const arr = menuList.map((o) => {
    if (o.children && o.children.length) {
      o.isActive = false
    }
    return o
  })
  return arr
}
export default {}
