import $api from '../index.js'

export const uploadImage = (data) => {
  return $api.post('/upload/common/image', data)
}

export const getUserInfo = () => {
  return $api.get('/admin/user/my/userinfo')
}
export default {}
