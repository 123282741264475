<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      :append-to-body="true"
      :fullscreen="true"
      title="查看大图"
      @close="close"
    >
      <div class="img-box">
        <img :src="src" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      src: ''
    }
  },
  props: {
    isView: {
      default() {
        return false
      }
    },
    url: {
      type: String,
      default() {
        return ''
      }
    }
  },
  watch: {
    isView: {
      handler(nv) {
        if (nv) {
          this.isShow = nv
        }
      },
      immediate: true
    },
    url: {
      handler(nv) {
        if (nv) {
          this.src = nv
        }
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.img-box {
  width: 100%;
  height: calc(100vh - 54px - 30px - 30px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  img {
    width: 30%;
  }
}
::v-deep .el-dialog__headerbtn {
  font-size: 32px !important;
}
::v-deep .el-dialog__title {
  font-size: 28px;
  font-weight: 600;
}
</style>
