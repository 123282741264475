export default [
  {
    path: '/index',
    name: '登录',
    component: () => import('@/views/login/index'),
    meta: {
      title: '登录认证'
    }
  }
]
