import $api from '../index'
// 登录获取token
export const getCode = (code) => {
  return $api.get('/auth/code', {
    params: {
      code
    }
  })
}
// 获取用户的信息
export const getUserInfo = (id) => {
  return $api.get(`/users/${id}/userInfo`)
}
// 退出登录
export const logout = (data) => {
  return $api.post('/logout', data)
}
// 更新密码
export const updatePassword = (data) => {
  return $api.post('/admin/user/my/password/modify', data)
}

// 刷新令牌toekn
export const getRefreshToken = (data) => {
  return $api.post('/auth/token/refresh', data)
}
export default {}
