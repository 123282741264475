import axios from 'axios'
import { getRefreshToken } from '@/api/login/index'
import { MessageBox, Message } from 'element-ui'
// 使用create方法来创建一个 axios实例
const $api = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL // 配置请求当中的一个共同的前置路径
})

// 是否正在刷新token
let isRefreshing = false
// 设置定时器,在过期前的10分钟内更新token
const interval = setInterval(() => {
  const refreshToken = window.localStorage.getItem('refreshToken')
  const expireTime = window.localStorage.getItem('expireTime')
  const diffTime = Math.ceil(expireTime ? expireTime - new Date().getTime() / 1000 : 1)
  if (diffTime < 600) {
    if (refreshToken && !isRefreshing) {
      isRefreshing = true
      const formData = new FormData()
      formData.append('refreshToken', refreshToken)
      refreshTokenOperation(formData)
      isRefreshing = false
    }
  }
}, 1000 * 10)

// 刷新token
async function refreshTokenOperation(data) {
  try {
    const res = await getRefreshToken(data)
    const { code } = res.data
    if (code === 200) {
      const currToken = res.data.data.token
      const currRefreshToken = res.data.data.refreshToken
      const expireIn = res.data.data.expireIn
      const expireTime = new Date().getTime() / 1000 + expireIn
      window.localStorage.setItem('token', currToken)
      window.localStorage.setItem('refreshToken', currRefreshToken)
      window.localStorage.setItem('expireIn', expireIn)
      window.localStorage.setItem('expireTime', expireTime)
    } else {
      clearInterval(interval)
    }
  } catch (error) {
    clearInterval(interval)
  }
}
$api.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

$api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { status } = error && error.response
    const { code } = error && error.response && error.response.data
    let { msg } = error && error.response && error.response.data
    if (!msg) {
      msg = '网络异常,请刷新重试'
    }
    if (status === 200 && code === 500) {
      msg ? Message.error(`${msg}`) : Message.error('网络异常')
    } else if (status === 500) {
      if (code === -1) {
        MessageBox.confirm('帐号已过期,请重新登录', '确定', {
          confirmButtonText: '确定',
          type: 'error',
          showClose: false,
          showCancelButton: false
        })
          .then(() => {
            window.location.href = process.env.VUE_APP_LOGIN_URL
          })
          .catch(() => {})
      } else if (code === 40001) {
        MessageBox.confirm('没有权限的操作', '确定', {
          confirmButtonText: '确定',
          type: 'error',
          showClose: false,
          showCancelButton: false
        })
          .then(() => {})
          .catch(() => {})
      } else if (code === 500) {
        Message.error(`${msg}`)
      } else {
        Message.error(`${msg}`)
      }
    } else if (status === 404) {
      MessageBox.confirm('404错误', '确定', {
        confirmButtonText: '确定',
        type: 'error',
        showClose: false,
        showCancelButton: false
      })
        .then(() => {})
        .catch(() => {})
    } else if (status === 401) {
      MessageBox.confirm('非法请求,请重新登录', '确定', {
        confirmButtonText: '确定',
        type: 'warning',
        showClose: false,
        showCancelButton: false
      })
        .then(() => {})
        .catch(() => {})
    } else {
      MessageBox.confirm('连接服务器失败', '确定', {
        confirmButtonText: '确定',
        type: 'error',
        showClose: false,
        showCancelButton: false
      })
        .then(() => {})
        .catch(() => {})
    }
    return error
  }
)

export default $api
