<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
::v-deep .cell {
  font-size: 16px;
  text-align: center;
}
#app {
  height: 100vh;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
::v-deep .el-form-item__label {
  font-size: 16px !important;
}
::v-deep .el-menu {
  border-right: 0 !important;
}
::v-deep .el-tooltip__popper {
  max-width: 20% !important;
}
::v-deep .el-button {
  margin-right: 10px !important;
}
</style>
