import Vue from 'vue'
/* eslint-disable */
import ElementUI from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import Page from '@/components/Page'
import viewImage from '@/components/viewImage'
import 'cropperjs/dist/cropper.css'
import 'element-ui/lib/theme-chalk/index.css'
import mixin from './utils/mixins'
// vue的错误处理
Vue.config.errorHandler = function (error, vm, info) {
  console.error('Global Error Handler:', error, vm, info)
  return false
}
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.component('page', Page)
Vue.component('viewImage', viewImage)
Vue.mixin(mixin)

const bus = new Vue()
Vue.prototype.$bus = bus
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
