import { jsonToFormData } from './formdata'
import dayjs from 'dayjs'

const mixin = {
  data() {
    const options = {
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.3)',
      text: '加载中, 请稍后',
      lock: true
    }
    return {
      options
    }
  },

  methods: {
    formatTime(val, tpl) {
      return dayjs(val).format(tpl)
    },
    jsonToFormData
  }
}
export default mixin
