<template>
  <el-scrollbar
    class="tag-scroll"
    view-class="tag-scroll-view"
    v-if="$store.state.cachesPages && $store.state.cachesPages.length"
  >
    <el-tag
      v-for="(item, index) in $store.state.cachesPages"
      :key="item.url"
      :closable="true"
      color="#fff"
      @click="tagclick(item)"
      @close="closetag(index, item)"
      :effect="$route.path === item.url ? 'plain' : 'light'"
      :hit="$route.path === item.url"
    >
      {{ item.name }}
    </el-tag>
  </el-scrollbar>
</template>

<script>
export default {
  name: 'bread',
  props: ['breadInfo'],
  computed: {
    breadcrumbRoutes() {
      return this.$route.matched
    }
  },
  methods: {
    tagclick(item) {
      if (this.$route.path === item.url) {
        return
      }
      this.$router.replace(item.url)
    },
    closetag(index, item) {
      if (index === 0) {
        return
      }
      if (this.$route.path === item.url) {
        this.$router.push(this.$store.state.cachesPages[index - 1].url)
      }
      this.$store.commit('delCachesPages', index)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-tag--light {
  margin-right: 10px;
  color: #000 !important;
  border-color: #ccc;
}
::v-deep .el-tag__close {
  color: #ccc !important;
}
::v-deep .el-tag--plain {
  margin-right: 10px;
}
.tag-scroll-view {
  max-width: 80%;
  overflow: hidden;
  display: flex !important;
  flex-wrap: nowrap;
}
::v-deep .el-scrollbar__wrap {
  box-sizing: border-box !important;
  margin: 0 !important;
  padding: 0;
  overflow-y: hidden;
  overflow-x: auto;
}
</style>
