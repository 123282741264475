<template>
  <el-menu
    :default-active="activedPath"
    class="menu1"
    :unique-opened="true"
    background-color="#21262A"
    text-color="#FFFFFF"
    active-text-color="#FFFFFF"
    @select="selectMenu"
    @open="openMenu"
    @close="closeMenu"
  >
    <template v-for="(item, index) in menuData">
      <el-submenu
        v-if="item.children && item.children.length"
        :index="item.url"
        :key="item.url"
        @click="active(index)"
      >
        <template slot="title">
          <div class="item-flex sub">
            <img
              v-if="openMenuPath === item.url"
              :src="require('../../assets/icon/' + item.icona + '.svg')"
              alt=""
            />
            <img v-else :src="require('../../assets/icon/' + item.icon + '.svg')" alt="" />
            <div class="text">{{ item.name }}</div>
            <img v-if="openMenuPath === item.url" src="../../assets/icon/arrowa.svg" alt="" />
            <img v-else src="../../assets/icon/arrow.svg" alt="" />
          </div>
        </template>
        <el-menu-item v-for="child in item.children" :key="child.url" :index="child.url">
          <div class="item-flex">
            <div class="little-box"></div>
            <div class="text">{{ child.name }}</div>
          </div>
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else :index="item.url" :key="item.url.toString()">
        <div class="item-flex">
          <!-- <div class="icon"> -->
          <img
            v-if="activedPath === item.url"
            :src="require('../../assets/icon/' + item.icona + '.svg')"
            alt="123"
          />
          <img v-else :src="require('../../assets/icon/' + item.icon + '.svg')" alt="345" />
          <!-- </div> -->
          <div class="text">{{ item.name }}</div>
        </div>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import menuObj from './menu'
import { mapGetters } from 'vuex'
import { menuConcat } from '@/utils/menuFunc'
import { getMenu } from '@/api/rights'
export default {
  name: 'menu1',
  computed: {
    ...mapGetters(['menu']),
    activedPath() {
      return this.$route.path
    }
  },
  data() {
    return {
      isActive: false,
      openMenuPath: '',
      // 菜单的结构
      menuData: [
        {
          name: '文章管理',
          url: '/essay',
          icon: 'title',
          icona: 'titlea'
        },
        {
          name: '客户管理',
          url: '/client',
          icon: 'user',
          icona: 'usera'
        },
        {
          name: '家承问答',
          url: '/ask',
          icon: 'qa',
          icona: 'qaa'
        },
        {
          name: '轮播图',
          url: '/slider',
          icon: 'slider',
          icona: 'slidera'
        },
        {
          name: '评论管理',
          url: '/comment',
          icon: 'comment',
          icona: 'commenta'
        },
        {
          name: '预约管理',
          url: '/reservation',
          icon: 'reservation',
          icona: 'reservationa'
        },
        {
          name: '咨询师管理',
          url: '/counselor',
          icon: 'counselor',
          icona: 'counselora'
        },
        {
          name: '操作日志',
          url: '/log',
          icon: 'log',
          icona: 'loga'
        },
        {
          name: '意见反馈',
          url: '/opinion',
          icon: 'order',
          icona: 'ordera'
        },
        {
          name: '咨询指南',
          url: '/guide',
          icon: 'slider',
          icona: 'slidera'
        },
        {
          name: '商品管理',
          url: '/goods',
          icon: 'good',
          icona: 'gooda'
        },
        {
          name: '测评管理',
          url: '/evaluation',
          icon: 'evaluationa',
          icona: 'evaluation'
        },
        {
          name: '二维码管理',
          url: '/qrcode',
          icon: 'slider',
          icona: 'slidera'
        },
        {
          name: '缓存管理',
          url: '/cache',
          icon: 'log',
          icona: 'loga'
        },
        {
          name: '分类管理',
          url: 'classify',
          icon: 'classify',
          icona: 'classifya',
          children: [
            {
              name: '课程分类',
              url: '/classify/course'
            },
            {
              name: '测评分类',
              url: '/classify/test'
            },
            {
              name: '咨询师分类',
              url: '/classify/counselor'
            },
            {
              name: '问答分类',
              url: '/classify/qa'
            }
          ]
        },
        {
          name: '订单管理',
          url: 'orders',
          icon: 'order',
          icona: 'ordera',
          children: [
            {
              name: '订单列表',
              url: '/order'
            },
            {
              name: '退款审批',
              url: '/refund'
            }
          ]
        },
        {
          name: '内容审批',
          url: 'approval',
          icon: 'approval',
          icona: 'approvala',
          children: [
            {
              name: '文章审批',
              url: '/approval/title'
            },
            {
              name: '课程审批',
              url: '/approval/course'
            },
            {
              name: '视频审批',
              url: '/approval/video'
            },
            {
              name: '测评审批',
              url: '/approval/evaluation'
            }
          ]
        },
        {
          name: '成长模块',
          url: 'course',
          icon: 'course',
          icona: 'coursea',
          children: [
            {
              name: '课程管理',
              url: '/lesson'
            },
            {
              name: '视频管理',
              url: '/video'
            }
          ]
        },
        {
          name: '权限管理',
          url: 'permission',
          icon: 'setup',
          icona: 'setupa',
          children: [
            {
              name: '员工管理',
              url: '/staff'
            },
            {
              name: '角色管理',
              url: '/role'
            },
            {
              name: '资源权限管理',
              url: '/assets'
            }
          ]
        }
      ]
    }
  },
  methods: {
    selectMenu(index) {
      if (index !== this.activedPath) {
        let findRouter
        // 当index的父url=order,classify,approval,course,rights时,需要在children里面找
        if (new RegExp('^' + '/classify').test(index)) {
          findRouter = menuObj.classify.find((item) => item.url === index)
        } else if (new RegExp('^' + '/approval').test(index)) {
          findRouter = menuObj.approval.find((item) => item.url === index)
        } else if (index === '/lesson' || index === '/video') {
          findRouter = menuObj.course.find((item) => item.url === index)
        } else if (index === '/order' || index === '/refund') {
          findRouter = menuObj.orders.find((item) => item.url === index)
        } else if (index === '/staff' || index === '/role' || index === '/assets') {
          findRouter = menuObj.permission.find((item) => item.url === index)
        } else {
          findRouter = this.menuData.find((item) => {
            return item.url === index
          })
        }
        if (findRouter) {
          this.$store.commit('addCachesPages', findRouter)
        }
        this.$router.replace(index)
      }
    },
    closeMenu(index) {
      if (index === this.openMenuPath) {
        this.openMenuPath = ''
      }
    },
    openMenu(index) {
      if (this.openMenuPath === index) {
        return
      }
      this.openMenuPath = index
      const findMenu = this.menuData.find((i) => i.url === index).children[0]
      this.$router.replace(findMenu.url)
      this.$store.commit('addCachesPages', findMenu)
    },
    async getMenu() {
      const res = await getMenu()
      const { code } = res.data
      if (code !== 200) {
        const { msg } = res.data
        if (msg) {
          this.$message.error(`${msg}`)
        }
        return
      }
      const menuObj = res.data.data
      // 接口菜单, 本地菜单
      this.menuData = menuConcat(menuObj, this.menuData)
    }
  },
  created() {
    this.getMenu()
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-menu-item {
  font-size: 18px;
  text-indent: 1em;
}
.item-flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.text {
  width: 190px;
}
img {
  width: 20px;
  height: 20px;
}
.sub {
  width: 80%;
  text-indent: 1em;
  font-size: 18px;
}
::v-deep .el-menu-item.is-active {
  background-color: #35a0f6 !important;
}
::v-deep .el-submenu__icon-arrow {
  display: none;
}
::v-deep .el-submenu__title {
  padding-right: 0 !important;
  padding-left: 40px !important;
}
::v-deep .el-menu-item {
  padding: 0 20px 0 40px !important;
}
.little-box {
  width: 20px;
  height: 20px;
}
</style>
