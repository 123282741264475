import Vue from 'vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'
import frameIn from './frameIn'
import frameOut from './frameOut'

NProgress.configure({ showSpinner: false })
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }
  return originalReplace.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children: frameIn,
    meta: {
      title: '首页'
    }
  },
  ...frameOut
]

const router = new VueRouter({
  routes,
  // mode: 'history'
  mode: 'hash'
})
// 全局访问router
window.$router = router

router.beforeEach((to, from, next) => {
  NProgress.start()
  document.title = to.meta.title || 'index'
  // 登录处理
  if (to.path === '/index') {
    // 去登录
    next()
  } else {
    const token = localStorage.getItem('token')
    if (token) {
      next()
    } else {
      window.location.href = process.env.VUE_APP_LOGIN_URL
    }
  }
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
