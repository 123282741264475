<template>
  <div class="content">
    <div class="full-icon" v-if="isFullscreen" @click="fullscreen">
      <img src="@/assets/common/min.png" alt="" />
    </div>
    <div class="full-icon" v-else @click="fullscreen">
      <img src="@/assets/common/max.png" alt="" />
    </div>
    <el-dropdown
      trigger="hover"
      placement="bottom-start"
      size="medium"
      @command="click1"
      @visible-change="change"
    >
      <div class="ava">
        <div v-if="data.avatar" class="avatar-wrapper">
          <img :src="data.avatar" alt="" />
        </div>
        <el-avatar v-else :size="50" :src="circleUrl"></el-avatar>
        <div>
          <i class="el-icon-caret-top" v-show="!isShow"></i>
          <i class="el-icon-caret-bottom" v-show="isShow"></i>
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item>
            <div style="display: flex; align-items: center; justify-content: center; width: 100%">
              <div
                style="
                  border-bottom: 4px solid #f7f6f6;
                  border-radius: 2px;
                  width: 90%;
                  display: flex;
                  align-items: center;
                "
              >
                <div class="avatar-wrapper1">
                  <img :src="data.avatar" alt="" />{{ data.nickName }}
                </div>
                <!-- <div class="nickname"></div> -->
              </div>
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="0">
            <div style="width: 100%; display: flex; align-items: center; justify-content: center">
              <i class="el-icon-setting"></i>
              修改密码
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="1">
            <div style="width: 100%; display: flex; align-items: center; justify-content: center">
              <i class="el-icon-switch-button"></i>
              退出登录
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dialog title="修改密码" :visible.sync="showDialog" width="500px">
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :rules="rules"
        show-message
        size="small"
      >
        <el-form-item prop="originalPwd" label="原密码"
          ><el-input v-model="form.originalPwd" placeholder="请输入原密码" show-password></el-input
        ></el-form-item>
        <el-form-item prop="newPwd" label="新密码"
          ><el-input v-model="form.newPwd" placeholder="请输入新的密码" show-password></el-input
        ></el-form-item>
        <el-form-item prop="confirmNewPwd" label="确认密码"
          ><el-input v-model="form.confirmNewPwd" placeholder="请确认密码" show-password></el-input
        ></el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="submit" type="primary">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo } from '@/api/common'
import { logout, updatePassword } from '@/api/login'
import { jsonToFormData } from '@/utils/formdata'
export default {
  data() {
    return {
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      isShow: false,
      form: {},
      rules: {
        confirmNewPwd: [{ required: true, message: '不能为空', trigger: 'blur' }],
        newPwd: [{ required: true, message: '不能为空', trigger: 'blur' }],
        originalPwd: [{ required: true, message: '不能为空', trigger: 'blur' }]
      },
      showDialog: false,
      data: {},
      isFullscreen: false
    }
  },
  components: {},
  methods: {
    async click1(command) {
      this.flag = 1
      if (command === '1') {
        // 退出登录
        const token = window.localStorage.getItem('token')
        if (!token) {
          window.location.href = process.env.VUE_APP_LOGIN_URL
          return
        }
        const formdata = jsonToFormData({ token })
        const res = await logout(formdata)
        const { code } = res.data
        if (code !== 200) {
          this.$message.error('退出登录失败')
        } else {
          window.localStorage.removeItem('token')
          this.$message.success('退出成功')
          window.location.href = process.env.VUE_APP_LOGIN_URL
        }
      } else if (command === '0') {
        // 修改密码
        this.showDialog = true
      }
    },
    change(flag) {
      this.form = {}
      if (flag) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    fullscreen() {
      this.isFullscreen = document.fullscreenElement
      if (this.isFullscreen) {
        // 退出全屏
        document.exitFullscreen()
      } else {
        document.documentElement.requestFullscreen()
      }
      this.isFullscreen = !this.isFullscreen
    },
    submit() {
      this.$refs.form.validate(async (pass) => {
        if (pass) {
          const formdata = jsonToFormData(this.form)
          const res = await updatePassword(formdata)
          const { code } = res.data
          if (code !== 200) {
            this.$message.error('修改失败')
            return
          }
          this.$message.success('修改成功')
          this.showDialog = false
        }
      })
    },
    async getUserInfo() {
      const res = await getUserInfo()
      const { code } = res.data
      if (code !== 200) {
        const { msg } = res.data
        if (msg) {
          this.$message.error(`${msg}`)
        }
        return
      }
      this.data = res.data.data
    }
  },
  created() {
    this.getUserInfo()
  }
}
</script>

<style scoped lang="scss">
.ava {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
  cursor: pointer;
}
.content {
  display: flex;
  align-items: center;
}
.avatar-wrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
  img {
    width: 100%;
    height: 100%;
  }
}
.avatar-wrapper1 {
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000;
  margin: 6px;
  font-weight: 800;
  img {
    width: 40px;
    height: 40px;
  }
}
::v-deep .el-dropdown-menu__item {
  width: 260px;
  height: 80px;
  font-size: 18px !important;
  line-height: 60px !important;
  font-weight: 700;
  text-indent: 1em;
  display: flex;
  align-items: center;
  padding: 0 !important;
  img {
    margin-left: 20px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
  }
}
::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff !important;
}
.full-icon {
  width: 20px;
  height: 20px;
  margin-right: 26px;
  opacity: 0.6;
  transition: all 0.5s;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    opacity: 1;
  }
}
</style>
