export function jsonToFormData(json, formData, parentKey) {
  if (formData === undefined) {
    formData = new FormData()
  }
  for (const key in json) {
    if (Object.hasOwnProperty.call(json, key)) {
      const propName = parentKey ? `${parentKey}[${key}]` : key
      if (typeof json[key] === 'object' && !Array.isArray(json[key])) {
        jsonToFormData(json[key], formData, propName)
      } else if (Array.isArray(json[key])) {
        json[key].forEach((item, index) => {
          const arrayKey = `${propName}[${index}]`
          if (typeof item === 'object' && !Array.isArray(item)) {
            jsonToFormData(item, formData, arrayKey)
          } else {
            formData.append(arrayKey, item)
          }
        })
      } else {
        formData.append(propName, json[key])
      }
    }
  }
  return formData
}
export default {}
